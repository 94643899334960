import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import Axios from 'axios';
import useSWR from 'swr';

import { CompatibleImg } from '../components/Img';
import PageLayout from '../components/PageLayout';
import { RichTextView, device, ContentMain } from '../components/commonStyled';
import { ROUTES, WP_API_URL } from '../constants';
import { FONT_GOTHAM_LIGHT } from '../styles/fonts';
import { parseHeaders } from '../utils';
import PostNav from '../components/PostNav';
import { Box, Flex } from '../components/Box';
import Spinner from '../components/Spinner';

export default () => {
    const [post, setPost] = useState(null);
    const { data: imageData } = useSWR(
        post &&
            `${WP_API_URL}/media/${post.featured_media}?_fields[]=media_details&_fields[]=alt_text`
    );

    useEffect(() => {
        const load = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const postId = urlParams.get('post');
            try {
                const { data } = await Axios.get(
                    `${WP_API_URL}/posts/${postId}`
                );

                setPost(data);
            } catch (e) {}
        };

        load();
    }, []);

    if (!post) {
        return (
            <PageLayout>
                <Spinner />
            </PageLayout>
        );
    }

    const {
        title: { rendered: title },
        content: { rendered: content },
        acf: {
            widget1_image,
            widget1_url,
            widget2_image,
            widget2_url,
            widgets_enabled,
        },
    } = post;

    const widgets = [
        { image: widget1_image.url, url: widget1_url },
        { image: widget2_image.url, url: widget2_url },
    ].filter((x) => x.url);

    const headers = parseHeaders(content);

    const sizes = imageData?.media_details?.sizes;
    const imageFull = sizes?.full?.source_url;

    return (
        <>
            <PageLayout
                contentProps={{
                    itemType: 'https://schema.org/Article',
                    itemScope: true,
                }}
                breadcrumbs={[
                    {
                        url: `/${ROUTES.articles}`,
                        title: 'Статьи',
                    },
                    {
                        title,
                        url: `${location?.pathname}`,
                    },
                ]}
                title={title}
                seoMeta={[]}
            >
                <Container>
                    {imageFull && (
                        <ImageWrap
                            itemScope
                            itemType="http://schema.org/ImageObject"
                        >
                            <CompatibleImg
                                imageStyle={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',
                                }}
                                image={imageFull}
                                itemProp="contentUrl"
                            />
                        </ImageWrap>
                    )}
                    <Flex>
                        <Box
                            width={widgets_enabled ? [1, 3 / 4, 3 / 4] : '100%'}
                        >
                            <PostNav headers={headers} />
                            <RichTextView
                                dangerouslySetInnerHTML={{ __html: content }}
                                itemProp="articleBody"
                            />
                        </Box>
                        {widgets_enabled && (
                            <Box
                                width={1 / 4}
                                pl="32px"
                                display={['none', 'block', 'block']}
                            >
                                <Box position="sticky" top={['90px', '100px']}>
                                    {widgets?.map(({ image, url }, index) => (
                                        <LinkStyled
                                            key={index}
                                            href={url}
                                            target="_blank"
                                            rel="nofollow"
                                        >
                                            <CompatibleImg
                                                image={image}
                                                imageStyle={{
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                            />
                                        </LinkStyled>
                                    ))}
                                </Box>
                            </Box>
                        )}
                    </Flex>
                </Container>
            </PageLayout>
        </>
    );
};

const LinkStyled = styled.a`
    display: block;
    margin-bottom: 24px;
`;

const BannerContainer = styled(Link)`
    width: 100%;
    margin-top: 32px;
    display: block;
`;

const ShareTitle = styled.div`
    color: #2e0f25;
    font-size: 32px;
    margin-bottom: 24px;
    text-align: center;
    font-family: ${FONT_GOTHAM_LIGHT};
`;

const ShareButtons = styled.div`
    display: flex;
    justify-content: center;
`;

const ShareButonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
`;

const Comments = styled.div`
    margin-top: 24px;
`;

const Container = styled(ContentMain)`
    display: block;
`;

const ImageWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 550px;
    border-radius: 33px;
    box-shadow: 0 1px 2px 0 rgba(25, 27, 26, 0.1),
        0 1px 8px 0 rgba(25, 27, 26, 0.08);
    overflow: hidden;

    margin-bottom: 48px;

    @media ${device.laptopSmall} {
        overflow: hidden;
        margin-bottom: 36px;
        margin-right: 0;
        height: 450px;
    }

    @media ${device.mobileMedium} {
        width: 100%;
        margin-bottom: 24px;
        height: 350px;
    }
`;
